$(document).ready(function() {

    if($(window).width() > 750) {
        changeBackground();
    }

    $.cookieBar({
        message: 'Ta strona używa Cookies. Korzystając z niej wyrażasz zgodę na używanie cookie, zgodnie z aktualnymi ustawieniami przeglądarki oraz <a href="/pdf/sgir/polityka_cookies.pdf" target="blank">regulaminem</a>.',
        acceptText: 'Rozumiem',
        fixed: true,
        bottom: true
    });


});

function changeBackground() {

    setTimeout(function() {
        $('.js-changeBackground').addClass('log-bg--on');
        setTimeout(function() {
            $('.js-changeBackground-login').addClass('login--on');
        }, 300);
    }, 1500);
}